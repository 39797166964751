import createProps from '@kissui/helpers/src/props.helpers'
import { APP_APPLE_LINK, APP_ANDROID_LINK, APP_HUAWEI_LINK } from '@kissui/components'
import { getMarketCode, getLangCode, interpolateMarketLang } from '@kissui/helpers/src/dataLayer'
import { handlePromoClick } from '@kissui/helpers/src/gtmEvents'

class FooterLogos extends HTMLElement {
    constructor() {
        super()
        this.market = getMarketCode()
        this.language = getLangCode()
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.render()
        this.linkElements = this.querySelectorAll('ul li a')
        this.bindEvent()
    }

    render() {
        const { id, name, creative, position } = this.props.campaign
        const { heading, size } = this.props.copywriting

        this.classList.add(`size-${size}`)

        let headingRender = ''
        if (heading) {
            headingRender = `<h2 class="t-sm-400-sl">${heading}</h2>`
        }

        this.innerHTML = `<nb-container
                campaign_id="${id}"
                campaign_name="${name}"
                campaign_creative="${creative}"
                campaign_position="${position}"
                classname="pt4 pb4"
                contrast="light">
                ${headingRender}
                ${this.renderItems()}
            </nb-container>`
    }

    renderItems() {
        const { items = [] } = this.props.copywriting

        const renderedItems = items.reduce(
            (output, item) => `${output}${this.renderItem(item)}`,
            ''
        )

        return `<ul>${renderedItems}</ul>`
    }

    renderItem({ label, icon, image, link, target_blank }) {
        const { is_app_download } = this.props.copywriting

        let target = '_self'
        if (target_blank === 'true') {
            target = '_blank'
        }

        // If app download, set app download links via constants
        if (is_app_download === 'true') {
            switch (icon) {
                case 'apple':
                case 'logotypes/store/apple-black':
                case 'logotypes/payment/apple':
                    link = APP_APPLE_LINK.default
                    link = link.replace('/us/', `/${this.market}/`)
                    break
                case 'android':
                case 'logotypes/store/android-black':
                case 'logotypes/payment/android':
                    link = APP_ANDROID_LINK
                    break
                case 'huawei':
                case 'logotypes/store/huawei-black':
                case 'logotypes/payment/huawei':
                    link = APP_HUAWEI_LINK
                    break
                default:
                    break
            }
        }

        let iconRender = ''
        if (icon && image === '') {
            iconRender = `<nb-icon icon="${icon}"></nb-icon><span class="sr-only">${label}</span>`
        }

        let imgRender = ''
        if (image) {
            imgRender = `<img loading="lazy" src="${image}" alt="${label}" />`
        }

        if (link) {
            const url = interpolateMarketLang(link, this.market, this.language)
            return `<li><a href="${url}" target="${target}">${iconRender}${imgRender}</a></li>`
        }

        return `<li><div>${iconRender}${imgRender}</div></li>`
    }

    linkClick({ textContent, index }) {
        const { campaign } = this.props

        handlePromoClick({
            campaign,
            cta_name: `LINK - ${index + 1} - ${textContent}`
        })
    }

    bindEvent() {
        if (this.linkElements?.length) {
            this.linkElements.forEach((element, index) => {
                const textContent = element.textContent
                element.boundLinkClick = this.linkClick.bind(this, { textContent, index })
                element.addEventListener('click', element.boundLinkClick)
            })
        }
    }

    unbindEvent() {
        if (this.linkElements?.length) {
            this.linkElements.forEach(element => {
                element.removeEventListener('click', element.boundLinkClick)
            })
        }
    }
}

customElements.get('nb-footer-logos') || customElements.define('nb-footer-logos', FooterLogos)
export default FooterLogos
